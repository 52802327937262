const _planName = (school) => {
  let name = 'scholabasics';
  if (school?.has_premium_access) {
    if (school.plan_id?.toLowerCase() === 'monthlypro' && school.subscription_months > 0) {
      // Recruiter Pro
      name = 'scholarecruiterpro';
    } else {
      // Plus
      switch (school.plan_id?.toLowerCase()) {
        case 'monthlyconnect':
        case 'monthlyconnect2':
        case 'yearlyconnect':
        case 'yearlyconnect2':
        case 'monthlyrecruiter':
        case 'monthlyrecruiter2':
        case 'yearlyrecruiter':
        case 'yearlyrecruiter2':
        case 'scholaplus':
          name = 'scholaplus';
          break;
        case 'monthlypro':
        case 'yearlypro':
        case 'payg':
        case 'scholarecruiterpro-evergreen':
        case 'scholarecruiterpro':
        case 'scholaone':
          name = 'scholarecruiterpro';
          break;
        case 'scholabasics':
          name = 'scholabasics';
          break;
      }
    }
  }
  return name;
};

const allPermissions = ['reachout', 'publish-campaign', 'view-lead-phone-email', 'export-leads', 'export-apps'];

const plans = [
  {
    planName: 'scholabasics',
    expiredays: 1,
    permissions: allPermissions.filter((item) => {
      return !['reachout', 'publish-campaign', 'view-lead-phone-email', 'export-leads', 'export-apps'].includes(item);
    }),
  },
  {
    planName: 'scholaplus',
    expiredays: 7,
    permissions: allPermissions.filter((item) => {
      return !['export-leads', 'export-apps'].includes(item);
    }),
  },
  {
    planName: 'scholarecruiterpro',
    expiredays: 0,
    permissions: allPermissions,
  },
];

export const planName = (school) => {
  return _planName(school);
};

export const hasPremium = (school) => {
  return school?.has_premium_access;
};

export const hasPermission = (school, option) => {
  const planName = _planName(school);
  let plan = plans.filter((p) => {
    return p.planName === planName;
  });

  return plan[0] && plan[0].permissions.includes(option);
};

export const expiredays = (school) => {
  const planName = _planName(school);
  let plan = plans.filter((p) => {
    return p.planName === planName;
  });

  return plan[0] && plan[0].expiredays;
};
